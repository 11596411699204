import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  AuthentificationService,
  Utilisateur,
  UtilisateurIdSendMessageBody,
  UtilisateursService,
} from 'src/app/server';
import { ConfigService } from '../../config.service';
import { environment } from 'src/environments/environment';
import { CommonNetexpoModule } from '../common-netexpo.module';
// import { faker } from '@faker-js/faker';
@Component({
  selector: 'app-user-block',
  templateUrl: './user-block.component.html',
  styleUrls: ['./user-block.component.scss'],
})
export class UserBlockComponent implements OnInit {
  @Input()
  user: Utilisateur;

  @Input()
  type: string = 'list';

  @Input()
  titre: string = 'Utilisateur';

  showCall: boolean = false;
  qrdataTel: string = '';
  qrdataMail: string = '';
  smsSent: boolean = false;
  linkSent: boolean = false;

  public alertInputs = [
    {
      type: 'number',
      placeholder: 'Age',
      attributes: {
        maxlength: 8,
      },
      min: 1,
      max: 100,
    },
  ];
  NoDefaultNotif: boolean = false;
  getOnlyOnceDefaultNotif: boolean = true;
  notifUser: string;
  defaultLoaded: boolean = false;
  qrsize: number;
  getUblock: any;
  isPhone: boolean;
  constructor(private common : CommonNetexpoModule,
    private alertCtrl: AlertController,
    private translate: TranslateService,
    public toastController: ToastController,
    private userSvc: UtilisateursService,
    private cfgSvc: ConfigService,
    private authSvc: AuthentificationService
  ) { }

  ngOnInit() {
    this.isPhone = document.body.clientWidth<1024 ? true : false
    this.qrdataTel = 'TEL:' + this.user.tel;
    this.qrdataMail = 'MAILTO:' + this.user.email;
    console.log("user block user : ",this.user);
    
  }

  async enableNotif(event) {
    event.stopPropagation();
    console.log(" enableNotif");
    let body = []
    if (this.user.tel) {
      body.push('sms')
    } else if (this.user.email) {
      body.push('mail')
    }
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('user.message'),
      subHeader: "voulez-vous activez les notifications par " + body[0] + " pour cet utilisateur",
      buttons: [
        {
          text: this.translate.instant('user.abort'),
          role: 'cancel',
          handler: () => {
            console.log('CANCELED');
          },
        },
        {
          text: "confirm",
          role: 'send',
          handler: async (alert) => {
            await this.userSvc.updateDefaultNotif(body, this.user.id).toPromise()
            this.getOnlyOnceDefaultNotif = true;
            this.NoDefaultNotif = false

          },
        },
      ],

    });
    await alert.present();


  }
  async contactUser(event) {
    this.getUblock =event.srcElement.closest('.userCard')
    
    if(!this.isPhone){
      this.qrsize=Math.trunc(this.getUblock.offsetWidth/13)
    }else{
      this.qrsize=Math.trunc(this.getUblock.offsetWidth/6)
    }
    if (this.type !== 'list' && this.type !== 'select') {
      this.showCall = !this.showCall;
      if (this.getOnlyOnceDefaultNotif) {
        this.notifUser = await this.userSvc.getDefaultNotif(this.user.id).toPromise()
        console.log("default notif", this.notifUser);
        this.defaultLoaded = true
        this.getOnlyOnceDefaultNotif = false;
      }
      if (this.notifUser.length === 0) {
        this.NoDefaultNotif = true
      }
      event.stopPropagation();
    } else {
      console.log ("Contact User - ", this.type);
    }
  }

  stopPropag(e) {
    e.stopImmediatePropagation();
  }

  async sendSMS() {
    let sendMessage =
      this.translate.instant('user.send') +
        this.user.tel ? 'SMS' : this.translate.instant('send.nophone') +
        this.translate.instant('user.to') +
        this.user?.prenomContact +
        ' ' +
      this.user?.nomContact;
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('user.message'),
      subHeader: sendMessage,
      buttons: [
        {
          text: this.translate.instant('user.abort'),
          role: 'cancel',
          handler: () => {
            console.log(' Alert SendSMS CANCELED');
          },
        },
        {
          text: this.translate.instant('user.tosend'),
          role: 'send',
          handler: async (alert) => {
            let config = await this.cfgSvc.getConfig();
            let msg =
              config.monEvenement.nomCourt +
              '(' +
              config.monUtilisateur.prenomContact +
              '):\n' +
              alert?.message +
              "\n[ this.translate.instant('user.sentfrom') " +
              // location.origin +
              environment.URL_EH +
              '/help?e=' +
              config.monEvenement.nomCourt +
              '&d=1 ]';
            let body: UtilisateurIdSendMessageBody = { message: msg };
            let result = await this.userSvc
              .sendMessageToUser(config.monEvenement.id, this.user.id, body)
              .toPromise();
            console.log(
              ' Alert SendSMS SEND to ',
              this.user?.tel,
              '/',
              this.user?.email,
              '[',
              alert?.message,
              ']'
            );
            this.presentToast(
              'Message sent to ' +
              this.user?.prenomContact +
              ' ' +
              this.user?.nomContact,
              'call'
            );
          },
        },
      ],
      inputs: [
        {
          name: 'message',
          id: 'textfield',
          type: 'textarea',
          placeholder: this.translate.instant('user.yourmess'),
        },
      ],
    });
    await alert.present();
    const textfield: HTMLElement = document.querySelector("#textfield");
    textfield.focus();
  }
  async alertesendSMSlink() {
    if (localStorage.getItem('dontasksmslink')) {
      this.sendSMSlink();
    } else {
      let config = await this.cfgSvc.getConfig();
      const alert = await this.alertCtrl.create({
        header: 'Confirmation',
        subHeader:
          this.translate.instant('send.message.hote') +
          ' ' +
          (config.monUtilisateur.tel
            ? 'n° ' + config.monUtilisateur.tel
            : 'email ' + config.monUtilisateur.email) +
          ' ' +
          this.translate.instant('send.message.link') + ' ' +
          this.user.prenomContact +
          ' ' +
          this.user.nomContact +
          ' ?',
        buttons: [
          {
            text: this.translate.instant('app.cancel'),
            role: 'cancel',
            handler: () => {
              console.log(' Alert SendSMSCall CANCELED');
            },
          },
          {
            text: this.translate.instant('user.tosend'),
            role: 'send',
            handler: async (alert) => {
              this.sendSMSlink();
            },
          },
          {
            text: this.translate.instant('send.dontask'),
            role: 'send',
            handler: async (alert) => {
              this.sendSMSlink();
              localStorage.setItem('dontasksmslink', 'true');
            },
          },
        ],
      });
      await alert.present();
    }
  }
  async sendSMSlink() {
    let smsLink = '';
    if (this.user.tel && this.user.tel.length > 0) {
      smsLink =
        this.translate.instant('user.call') +
        this.user.prenomContact +
        ' ' +
        this.user.nomContact +
        this.translate.instant('user.ph') +
        this.user.tel;
    } else {
      smsLink =
        this.translate.instant('user.nonumb') +

        this.user.prenomContact +
        ' ' +
        this.user.nomContact +
        this.translate.instant('user.sendemail') +
        this.user.email;
    }
    console.log('SendSMSLink userBlock - SMS', smsLink);
    let config = await this.cfgSvc.getConfig();
    console.log('SendSMSLink userBlock - config', config);
    let body: UtilisateurIdSendMessageBody = { message: smsLink };
    let result = await this.userSvc
      .sendMessageToUser(config.monEvenement.id, config.monUtilisateur.id, body)
      .toPromise();
    this.smsSent = true;
    this.presentToast('Sms to call sent', 'call');
    console.log('SendSMSLink userBlock - result=', result);
  }
  async alertesendMagicLink() {
    if (localStorage.getItem('dontaskMagicLink')) {
      this.sendMagicLink();
    } else {
      const alert = await this.alertCtrl.create({
        header: 'Confirmation',
        subHeader:
          this.translate.instant('send.magic') +
          ' ' +
          this.user?.prenomContact + ' ' +
          this.user?.nomContact +
          ' ?',
        buttons: [
          {
            text: this.translate.instant('app.cancel'),
            role: 'cancel',
            handler: () => {
              console.log(' Alert SendMagicLink CANCELED');
            },
          },
          {
            text: this.translate.instant('user.tosend'),
            role: 'send',
            handler: async (alert) => {
              this.sendMagicLink();
            },
          },
          {
            text: this.translate.instant('send.dontask'),
            role: 'send',
            handler: async (alert) => {
              this.sendSMSlink();
              localStorage.setItem('dontaskMagicLink', 'true');
            },
          },
        ],
      });
      await alert.present();
    }
  }
  async sendMagicLink() {
    console.log(
      'SendMagicLink userBlock - start tel=',
      this.user.tel,
      'mail=',
      this.user.email
    );
    let config = await this.cfgSvc.getConfig();
    let loginBody =
      !this.user.tel || this.user.tel.length == 0
        ? {
          evenementId: config.monEvenement.id,
          phone: '',
          mail: this.user.email,
        }
        : {
          evenementId: config.monEvenement.id,
          phone: this.user.tel,
          mail: '',
        };
    try {
      const result = await this.authSvc.login(loginBody).toPromise();
    } catch (error) {
      console.log('SendMagicLink userBlock - error', error);
    } finally {
      console.log('SendMagicLink userBlock - finally');
    }
    this.linkSent = true;
    this.presentToast('Link sent', 'sync');
    console.log('SendMagicLink userBlock - end');
  }

  async presentToast(msg: string, icon: string) {
    const toast = await this.toastController.create({
      // header: type,
      message: msg,
      icon: icon,
      position: 'bottom',
      cssClass: 'toastCustom',
      duration: 5000,
      // buttons: [
      //   {
      //     side: 'end',
      //     icon: 'log-out-outline',
      //     text: this.translate.instant('home.logout'),
      //     handler: () => {
      //       localStorage.removeItem("Token");
      //       this.cfg = undefined;
      //       this.currentEvt = undefined;
      //       this.currentUser = undefined;
      //       this.router.navigateByUrl("/authentification");
      //     }
      //   }
      // ]
    });
    toast.present();
  }
}
